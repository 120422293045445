jQuery(document).ready(function ($) {

    function preventDefaultFunc(event) {
        event.preventDefault();
    }

    /*New Menu*/
    if ($(window).width() < 1024) {
        /*Open and Close List Items*/
        $('.toplist-dropdown').hide();
        $('li.menu-item.menu-item-has-children').find('a').css({ 'width': 'fit-content', 'position': 'static' });

        $('li.menu-item.menu-item-has-children').find('i.fa.fa-caret-down').css({
            'position': 'absolute',
            'top': '16px',
            'right': '4%',
            'margin': '0px'
        });
        $('.sub-menu').find('i.fa.fa-caret-down').css({ 'float': 'right', 'margin': '0px' });

        $('li.menu-item.menu-item-has-children').find('i.fa.fa-caret-down').click(function (e) {
            e.preventDefault();
        })
        $('.sub-menu').find('i.fa.fa-caret-down').click(function (e) {
            e.preventDefault();
        })

        $('li.menu-item.menu-item-has-children').click(function (e) {
            if (!$(e.target).is('a')) {
                e.stopPropagation();
                $(this).children('.sub-menu').slideToggle();
            }
        })

        $('#toggle-menu > i').click(function () {
            $('li.menu-item.menu-item-has-children').each(function () {
                if ($(this).children('.sub-menu').is(':visible')) {
                    $(this).children('.sub-menu').slideToggle();
                }
            })
        })
    }

    $('.subnav-content li').each(function (index) {
        if ($(this).children().length > 1) {
            $(this).find('i.fa.fa-caret-up').remove();
            $(this).find('i.fa.fa-caret-down').attr('data-attr', 'sub-submenu-drop');
            $(this).find('i.fa.fa-caret-right').attr('data-attr', 'sub-submenu-drop');
        }
    })

    /*Toggle Menu*/
    var hidden = 0;
    var hidden_prompt = 0;

    $('#toggle-menu').click(function () {
        $('header#header').each(function () {
            this.style.setProperty('height', 'auto', 'important');
        });
        $('nav#primary_nav_wrap').toggleClass('menu-hide');
        $('#toggle-menu .fa-times').toggle();
        $('#toggle-menu .fa-bars').toggle();
        $('#toggle-search').toggle();
    })

    $(document).mouseup(e => {
        if (!$('#toggle-subnav').is(e.target) && $('#toggle-subnav').has(e.target).length === 0 &&
            !$('#subnav').is(e.target) && $('#subnav').has(e.target).length === 0) {
            if ($('#toggle-subnav').hasClass("toggle-subnav-active")) {
                $('#toggle-subnav').toggleClass("toggle-subnav-active toggle-subnav-inactive");
                $('#subnav').toggleClass("subnav-class subnav-class-hide");
            }
        }
    });

    $('#toggle-header-disclaimer').click(function () {
        $('#header-disclaimer').toggleClass("hidden");
    })

    $('#toggle-subnav').on('click', () => {
        $('header#header').each(function () {
            this.style.setProperty('overflow', 'unset');
        });
        $('#toggle-subnav').toggleClass("toggle-subnav-active toggle-subnav-inactive");
        $('#subnav').removeAttr('style');
        $('#subnav').toggleClass("subnav-class subnav-class-hide");
    });

    if ($(window).width() >= 1024) {

        let arrows = $("[data-attr]");
        let subListItem = $('.sub-menu li');

        $('#menu-subnav_menu li').each(function () {
            if ($(this).children('.sub-menu').children('li').length > 12) {
                $(this).children('.sub-menu').css({
                    'max-height': '450px',
                    'overflow-y': 'scroll',
                    'overflow-x': 'hidden'
                });
            }
        });

        $('#menu-subnav_menu').children('li').hover(function () {
            $(this).children('.sub-menu').stop(true, false, true).slideToggle(400);
            arrows.each(function () {
                $(this).removeClass('orange');
            })
            $('.sub-menu').each(function () {
                $(this).removeClass('sub-hover');
            });
        });

        subListItem.mouseover(function () {
            $(this).addClass('sub-menu-hover');
        }).mouseout(function () {
            $(this).removeClass('sub-menu-hover');
        });

        subListItem.hover(function () {
            $(this).siblings().find('[data-attr]').removeClass('orange');
            $(this).removeClass('sub-hover');
            if ($(this).children('.sub-menu').hasClass('sub-hover')) {
                $(this).children('.sub-menu').removeClass('sub-hover')
            }
        });

        $('.sub-menu li a').hover(function () {
            $(this).addClass("orange");
        }, function () {
            $(this).removeClass("orange");
        });

        arrows.on('click', function (ev) {
            ev.preventDefault();
        });

        arrows.mouseover(function () {
            if ($(this).hasClass('orange')) {
                $(this).removeClass('orange');
            } else {
                $(this).addClass('orange');
            }
            if ($(this).hasClass('fa-caret-right')) {
                $(this).parent().siblings('.sub-menu').toggleClass('sub-hover');
            }
        });
    }

    $('#toggle-search').click(function () {
        $('.new-right.nav-search').toggleClass('menu-hide');
        searchCasinos();
        $('#toggle-search .fa-times').toggle();
        $('#toggle-search').toggleClass('move-left');
        $('#toggle-search .fa-search').toggle();
        $('#toggle-menu').toggle();
    })

    $('#toggle-search').last().click(function () {
        $('#search-bar-input').val('');
    })

    //Search Function
    $(".nav-search i").unbind('click').click(function () {
        if ($(window).width() >= 1024) {
            $('.nav-search i').toggle();
            searchCasinos();
            window.onclick = function (event) {
                if (event.target == document.getElementById("search-function-box")) {
                    $('#search-bar-input').toggle();
                    $('#search-function-box').toggle();
                    $('.nav-search i').toggle();
                }
            }
            $('.nav-search').last().click(function () {
                $('#search-bar-input').val('');
            })
        }
    });

    var adserver = $(".hero-banner-body");
    var main = $("#main.static-page");

    if ($(adserver).length) {
        $(main).css("padding-top", 145);
    }
    if ($(window).width() > 990) {
        $(main).css("padding-top", 145);
    }

    function searchCasinos() {
        $('#search-bar-input').toggle();
        $('#search-function-box').toggle();
        $('input#search-bar-input').focus();
    }


    if ($('#back-to-top').length) {
        var scrollTrigger = 100, // px
            backToTop = function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scrollTrigger) {
                    $('#back-to-top, #home-button').addClass('show');
                } else {
                    $('#back-to-top, #home-button').removeClass('show');
                }
            };
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
        $('#back-to-top').on('click', function (e) {
            // e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });
    }

    $('#search-bar-input').on("keyup change click", function (e) {
        typewatch(function () {
            var args = {};
            args['str'] = $('#search-bar-input').val();
            jQuery.get('/wp-json/search/posts', args, function (data) {
                jQuery('.search-function-results').html(data);
            });
        }, 200);
    });

    var typewatch = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    // banner logic (if there is a feature mobile image =  don't show desktop image)
    let width = $(window).width(); 
    if((width < 768)){
        if($('.banner-mobile').length >= 1){
            $(".banner-desktop").hide();
        } else {
            $(".banner-desktop").show();
        }
    }

    // if href element attribute is empty add an # (for SEO)
    // $("#menu-header-menu li a").each(function () {
    //     if ($(this).attr("href") === undefined) {
    //         $(this).attr("href", "#");
    //     }
    // });
})
